.AppBody .history-table-container{
    padding: 0;
    .history-card-container{
     max-width: 100%;
     height: 6rem;
    }
    .breadcrumb-container-history{
        padding: 0.625rem 1.25rem;
        .home-page{
            color: black;
        }  
    }
    .text-content{
        padding: 0.625rem 2.8rem;
        font-weight: 600;
    }
    .operator-text{
        margin-left: 7rem;
        padding-top: 0.625rem;
        color:#63728A;
        font-weight: 700;
    }
    .combo-operator-col{
        margin-left: 2rem;
        @media (max-width: 1600px){
            margin-left: 5rem;
        }
        @media (max-width: 800px){
            margin-left: 7rem;
        }
    }
    .upload-history-no-data{
        .nswc-text{
            color: #63728A;
            margin-top: 8rem;
            display: flex;
            justify-content: center;
         }
         .checker-nswc-spinner{
            opacity: 1;
            left: 45%;
            margin-top: 8rem;
        }
    }
    .history-data-table{
        height : 80vh;
        div[class$="body"] {
            min-height: 55vh;
        }
    }
}