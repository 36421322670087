.app-container {
    .export-container{
            .export-button {
                border-radius: 0.3rem;
                background-color: #002D80;
                color: #FFFFFF;
                margin-top: 0.3rem;
                &:hover &:focus &:focus-visible {
                    margin-top: 2.5rem;
                    background-color: #002D80;
                    color: #FFFFFF;
                }
            }
        }
    .tabular-export-container{
        .export-download-button-CHECKER {
            border-radius: 0.3rem;
            background-color: #002D80;
            color: #FFFFFF;
            margin-top: 0.2rem;
            pointer-events: auto;
            &:hover &:focus &:focus-visible {
                margin-top: 2.5rem;
                background-color: #002D80;
                color: #FFFFFF;
            }
        }
        .export-download-button-PART, .export-download-button-SB {
            border-radius: 0.3rem;
            background-color: #002D80;
            color: #FFFFFF;
            margin: 0.3rem 1rem 0rem 1rem;
            pointer-events: auto;
            &:hover &:focus &:focus-visible {
                margin-top: 2.5rem;
                background-color: #002D80;
                color: #FFFFFF;
            }
        }
    }
}
 body .export-tooltip{
    width: 18rem;
}
body ul[class^="export-menulist"]{
    min-width: 6.25rem;
}
